@import '../../assets/scss/magic';
aside {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    padding: rem($globalGap) rem($globalGap - 16);
    z-index: 100;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    mix-blend-mode: difference;
    @media screen and (max-width: 1194px) {
        display: none;
    }
}
.social-links {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > li {
        + li {
            margin: rem(20) 0 0;
        }
    }
    a {
        width: rem(42);
        height: rem(42);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: rem(20);
        color: white;
        transition: 500ms transform;
        @include hover {
            transform: scale(1.5);
        }
    }
    @include hover {
        a {
            &:not(:hover) {
                transform: scale(0.8);
            }
        }
    }
}
