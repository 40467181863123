@import '../../assets/scss/magic';
.icon {
    --font-size: #{rem(24)};
    font-size: var(--font-size);
    display: flex;
    align-items: center;
    justify-content: center;
    &.s-18 {
        --font-size: #{rem(18)};
    }
    &.s-20 {
        --font-size: #{rem(20)};
    }
    &.s-26 {
        --font-size: #{rem(26)};
    }
    &.s-32 {
        --font-size: #{rem(32)};
    }
    &.s-48 {
        --font-size: #{rem(48)};
    }
    &.s-80 {
        --font-size: #{rem(80)};
    }
    &.s-120 {
        --font-size: #{rem(120)};
    }
    &.icon-spinner-third {
        &:before {
            display: block;
            animation: rotate 250ms infinite linear;
            @keyframes rotate {
                from {
                    transform: scale(0.8) rotate(0deg);
                }
                to {
                   transform: scale(0.8) rotate(380deg);
                }
            }
        }
    }
}
