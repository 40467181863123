@import "magic";
@import "reset";
:root {
    --header-height: #{rem(100)};
    @each $name, $color in $c {
        --#{$name}: #{$color};
    }
}
html {
    font: 300 10px / 1.8 $f;
    -webkit-text-size-adjust: 100%;
    background: c(page-background);
    color: sc(b, 0.9);
    min-height: 100%;
    display: flex;
    user-select: none;
    &, body, #root {
        width: 100%;
    }
    @include desktop {
        @media screen and (max-width: 1194px) {
            font-size: 9.62px;
        }
        @media screen and (max-width: 1024px) {
            font-size: 8.4px;
        }
    }
}
body {
    font-size: rem(14);
    overscroll-behavior: none none;
    display: flex;
    #hubspot-messages-iframe-container {
        &.widget-align-right {
            @include desktop {
                right: rem($globalGap - (108 - 92)) !important;
                bottom: rem($globalGap - 16) !important;
                opacity: 0;
                animation: fadeInUp 1s forwards 800ms;
            }
            &:not([style*="100%"]) {
                @include mobile {
                    opacity: 0;
                    z-index: 200 !important;
                    right: rem(17) !important;
                    bottom: rem(17) !important;
                    transform: scale(0.8) translate3d(0, 200%, 0);
                    transform-origin: bottom right;
                    transition: 500ms transform, 500ms opacity;
                    .sticky & {
                        transform: scale(0.8) translate3d(0, 0, 0);
                        opacity: 1;
                    }
                }
            }
        }
    }
}
#root {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 1fr auto;
    grid-template-areas: 'main' 'footer';
}
.main-holder {
    grid-area: main;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}
main {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: c(b);
    position: relative;
    z-index: 1;
    &.header-padding {
        padding: var(--header-height) 0 0;
    }
}
.guide {
    width: 100%;
    margin: auto;
    max-width: rem(890 + $globalGap * 2);
    padding: 0 rem($globalGap);
    @include mobile {
        max-width: rem(600 + $globalGap * 2);
    }
    .s-blog &,
    .s-blog ~ * &,
    .s-portfolioSubPage &,
    .s-portfolioSubPage ~ * & {
        max-width: rem(1200 + $globalGap * 2);
        @include mobile {
            padding: 0 rem(25);
            max-width: rem(600 + $globalGap * 2);
        }
    }
    @include mobile {
        padding: 0 rem($mobileGap);
    }
}
h3 {
    font: 200 rem(66) $f;
    letter-spacing: -rem(3);
    margin: 0 0 rem(100);
    @include mobile {
        font-size: rem(44);
        margin: 0 0 rem(50);
        letter-spacing: -rem(1);
        text-align: center;
    }
    &:not(.start) {
        text-align: center;
    }
    @include desktop {
        &.cut {
            width: 50%;
        }
    }
    span {
        @include accent-title;
        font-weight: 700;
    }
}
.link-effect {
    position: relative;
    padding: 0 0 rem(3);
    display: inline-block;
    transform: translate3d(0, 0, 0);
    &:after, &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: rem(1);
        background: c(b);
        transform-origin: right;
        transform: scaleX(1) translateZ(0);
        transition: transform .6s cubic-bezier(.165,.84,.44,1) .3s;
    }
    &:after {
        transform-origin: left;
    }
    &:not(:hover):after, &:hover:before {
        transform: scaleX(0) translateZ(0);
        transition: transform .6s cubic-bezier(.165,.84,.44,1);
    }
    &.hover-active {
        &:not(:hover):not(.active) {
            &:before {
                transform: scaleX(0) translateZ(0);
            }
        }
    }
}
.cards-group {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(100);
    @include mobile {
        grid-row-gap: rem(80);
    }
}
.absolutely-splash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
