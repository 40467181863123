@import '../../assets/scss/magic';
header {
    --animate: #{rem(100)};
    position: sticky;
    top: 0;
    width: 100%;
    height: var(--header-height);
    display: flex;
    align-items: center;
    z-index: 100;
    transition: 250ms transform, 300ms box-shadow;
    box-shadow: inset 0 0 0 c(b);
    .sticky & {
        @include desktop {
            box-shadow: inset 0 var(--header-height) 0 c(b);
        }
        @include mobile {
            &:not(.show-menu) {
                box-shadow: inset 0 var(--header-height) 0 c(b);
            }
        }
    }
    &.filled {
        box-shadow: inset 0 var(--header-height) 0 c(b);
    }
    &.hide {
        transition: 600ms transform, 300ms box-shadow;
        &:not(:hover):not(:focus-within) {
            @include desktop {
                transform: translate3d(0, -100%, 0);
            }
        }
        &:not(.show-menu):not(:focus-within) {
            @include mobile {
                transform: translate3d(0, -100%, 0);
            }
        }
    }
    &.show-menu {
        z-index: 300;
    }
    ~ main {
        margin: calc(-1 * var(--header-height)) 0 0;
    }
    .divider {
        width: rem(1);
        height: rem(26);
        margin: 0 rem(22);
        background: sc(b);
    }
    .p-section {
        font: 700 rem(14) / 1 $f;
        color: c(hero);
        text-transform: uppercase;
        @include hover-active {
            color: sc(b);
        }
     }
}
.header-content {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    padding: 0 rem($globalGap);
    position: relative;
    @include mobile {
        padding: 0 rem($mobileGap);
    }
    > li {
        display: flex;
        align-items: center;
        &:first-child {
            .animated-holder {
                width: auto;
            }
        }
    }
}
.toggle-navigation-holder {
    position: absolute;
    top: 50%;
    right: rem($mobileGap - 12);
    transform: translate3d(0, -50%, 0);
    .s-blog & {
        right: rem(25 - 12);
        @include ipad-portrait {
            right: rem(86);
        }
    }
    @include desktop {
        display: none;
    }
    button {
        width: rem(48);
        height: rem(48);
        &:before, &:after {
            content: '';
            display: block;
            position: absolute;
            background: sc(b);
            height: rem(1);
            width: rem(24);
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            transition: 300ms transform, 300ms top, 300ms bottom;
        }
        &:before {
            top: rem(20);
        }
        &:after {
            bottom: rem(20);
        }
        &.active {
            &:before {
                top: 50%;
                transform: translate3d(-50%, -50%, 0) rotate(45deg);
            }
            &:after {
                bottom: 50%;
                transform: translate3d(-50%, 50%, 0) rotate(-45deg);
            }
        }
    }
}
.logo-holder {
    display: flex;
    svg {
        height: rem(26);
        @include mobile {
            height: rem(24);
        }
        path, .fill-hero {
            transition: 400ms fill, 400ms stroke;
        }
        path {
            fill: sc(b);
            @include desktop {
                stroke: sc(b);
            }
        }
        .fill-hero {
            fill: c(hero);
            @include desktop {
                stroke: c(hero);
            }
        }
        @include hover {
            path {
                fill: c(hero);
                @include desktop {
                    stroke: c(hero);
                }
            }
            .fill-hero {
                fill: sc(b);
                @include desktop {
                    stroke: sc(b);
                }
            }
        }
    }
}
nav {
    display: flex;
    align-items: center;
    @include mobile {
        flex-direction: column;
    }
    a {
        + a {
            @include desktop {
                margin: 0 0 0 rem(60);
                .s-blog & {
                    margin: 0 0 0 rem(40);
                }
            }
            @include mobile {
                margin: rem(60) 0 0;
            }
        }
        letter-spacing: rem(2);
        display: block;
        font: 700 rem(12) / rem(16) $f;
        text-transform: uppercase;
        @include mobile {
            font: 700 rem(12) / rem(22) $f;
            letter-spacing: rem(8);
            text-align: center;
        }
        @include hover-active {
            color: c(hero);
        }
    }
}
@include mobile {
    .navigation-container {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 90vh;
        backdrop-filter: blur(rem(10)) brightness(1.2);
        background: c(b, 0.4);
        z-index: -1;
        padding: var(--header-height) rem($mobileGap);
        display: flex;
        transform: translate3d(0, -150%, 0);
        transition: 0.8s transform;
        .show-menu & {
            transform: translate3d(0, 0, 0);
        }
    }
    .navigation-holder {
        width: 100%;
        margin: auto;
    }
}
.scroll-progress-holder {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    .scroll-progress {
        width: 0;
        background: c(hero);
        height: rem(2);
        max-width: 100%;
    }
}
