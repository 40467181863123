@import '../../assets/scss/magic';
footer {
    grid-area: footer;
    width: 100%;
    background: c(footer);
    color: c(b);
    display: flex;
    padding: rem($globalGap) 0;
    @include mobile {
        padding: rem(80) 0;
    }
    @include desktop {
        position: sticky;
        bottom: 0;
        top: 0;
        min-height: calc(100vh - var(--header-height));
    }
}
.footer-content {
    width: 100%;
    display: grid;
    grid-template-columns: 1.8fr 1fr 1fr;
    grid-column-gap: rem($globalGap);
    @include mobile {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto auto;
        grid-template-areas: 'f f' 'm o' 's s';
        grid-row-gap: rem(80);
    }
    > li {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        &.footer-social-holder {
            @media screen and (min-width: 1195px) {
                display: none;
            }
            @include desktop {
                grid-column: span 3;
            }
        }
        @include mobile {
            &:nth-child(1) {
                grid-area: f;
            }
            &:nth-child(2) {
                grid-area: m;
            }
            &:nth-child(3) {
                grid-area: 0;
            }
            &:nth-child(4) {
                grid-area: s;
            }
        }
    }
}
.footer-widget {
    width: 100%;
    font: 400 rem(16) / rem(18) $f;
    color: c(b, 0.8);
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(18);
    @include mobile {
        font-size: rem(14);
    }
    > p {
        color: c(b, 0.6);
    }
    > ul {
        font: 300 rem(24) / 1 $f;
        display: grid;
        grid-template-columns: 100%;
        grid-row-gap: rem(30);
        @include mobile {
            font-size: rem(20);
        }
    }
    a {
        @include hover-active {
            color: c(b);
        }
    }
}
.contact-us-footer {
    font: 100 rem(44) / 1 $f;
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(30);
    @include mobile {
        width: 100%;
        font: 100 rem(32) / 1 $f;
        grid-row-gap: rem(20);
        text-align: center;
    }
    a {
        font-weight: 700;
    }
}
.contact-mail {
    margin: rem(160) 0 0;
    font: 300 rem(16) / rem(18) $f;
    letter-spacing: rem(0.8);
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: rem(18);
    a {
        font-size: rem(20);
    }
    @include mobile {
        width: 100%;
        text-align: center;
        margin: rem(50) 0 0;
        font: 400 rem(14) / rem(18) $f;
        grid-row-gap: rem(10);
        a {
            font-size: rem(16);
        }
    }
}
.footer-social-links {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    @include mobile {
        justify-content: center;
    }
    > li {
        margin: 0 rem(20);
    }
}
.copyright-holder {
    margin: rem($globalGap * 2) 0 0;
    font: 300 rem(13) / rem(17) $f;
    letter-spacing: rem(1);
    text-align: center;
    opacity: 0.6;
    @include mobile {
        margin: rem(60) 0 0;
        font-size: rem(12);
    }
}
