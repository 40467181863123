@import '../../assets/scss/magic';
.btn {
    --size: var(--button-custom-size, #{rem(36)});
    --font-size: var(--button-custom-font-size, #{rem(14)});
    --tooltip-offset: #{rem(8)};
    min-width: var(--size);
    min-height: var(--size);
    border-radius: var(--size);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font: 500 var(--font-size) / rem(20) $f;
    cursor: pointer;
    color: inherit;
    border: rem(1) solid transparent;
    transition: 400ms color, 400ms background, 400ms opacity, 600ms letter-spacing, 600ms padding, 250ms box-shadow linear;
    position: relative;
    text-transform: capitalize;
    &:disabled {
        opacity: 0.5;
    }
    &.loading, &:disabled {
        pointer-events: none;
    }
    &.id-start {
        > * {
            + * {
                margin-inline-start: calc(var(--size) / 4);
            }
        }
    }
    &.id-end {
        flex-direction: row-reverse;
        > * {
            + * {
                margin-inline-end: calc(var(--size) / 4);
            }
        }
    }
    &.cr-smooth {
        border-radius: rem(4);
    }
    &.s-small {
        --size: #{rem(26)};
        --font-size: #{rem(12)};
    }
    &.s-medium {
        --size: #{rem(34)};
        --font-size: #{rem(12)};
    }
    &.s-big {
        --size: #{rem(54)};
        --font-size: #{rem(14)};
        @include mobile {
            --font-size: #{rem(16)};
            --size: #{rem(56)};
        }
    }
    &.s-in-big {
        --size: #{rem(54)};
        --font-size: #{rem(14)};
        @include mobile {
            --size: #{rem(50)};
            --font-size: #{rem(14)};
        }
    }
    &.s-extra-big {
        --size: #{rem(60)};
        --font-size: #{rem(16)};
        @include mobile {
            --size: #{rem(56)};
        }
    }
    &:not(.c-icon):not(.c-one) {
        padding: 0 calc(var(--size) / 1.5);
        &.f-content-size {
            .icon {
                margin: 0 -rem(4);
            }
        }
        &.f-default {
            min-width: rem(110);
        }
        &.f-full-width {
            width: 100%;
        }
    }
    & {
        &.a-outline {
            border-color: c(hero);
            color: c(hero);
            &:hover, &.popover-opened {
                background: c(hero);
                color: sc(hero);
            }
            &:active {
                background: c(hero);
                color: sc(hero);
            }
        }
        &.a-minimal {
            &:not(.c-default) {
                color: c(hero);
            }
            &:hover, &.active, &.popover-opened {
                background: sc(b, 0.03);
            }
        }
        &.a-grayscale {
            background: sc(b, 0.01);
            &:hover, &.active, &.popover-opened {
                background: sc(b, 0.03);
            }
            &:not(.c-default) {
                color: c(hero);
            }
        }
        &.a-light {
            background: c(hero, 0.07);
            color: c(hero);
            &:hover, &.active, &.popover-opened {
                background: c(hero);
                color: sc(hero);
            }
        }
        &.a-clean {
            color: sc(d);
            background: c(d);
            font-weight: 400;
            box-shadow: 0 0 0 0 c(d);
            @include hover {
                box-shadow: 0 0 0 rem(5) c(d);
                .icon {
                    transform: translateX(rem(6));
                }
            }
        }
    }
    &.a-default {
        background: c(hero);
        color: sc(hero);
        &:hover, &.popover-opened {
            background: ch(hero);
        }
    }
    &.c-accent {
        @include over(hero, accent);
        --hero-hover: var(--accent-lighten);
    }
    &.c-brand {
        @include over(hero, brand);
    }
    &.c-danger {
        @include over(hero, danger);
    }
    &.c-confirm {
        @include over(hero, confirm);
    }
    &.c-warning {
        @include over(hero, warning);
    }
    &.c-peach {
        @include over(hero, peach);
    }
    &.c-jaffa {
        @include over(hero, jaffa);
    }
    &.animation {
        animation: waves 1s alternate infinite;
        @keyframes waves {
            to {
                box-shadow: 0 0 0 rem(5) c(hero, 0.2),
                0 0 0 rem(10) c(hero, 0.2),
                0 0 0 rem(15) c(hero, 0.2),
                0 0 0 rem(20) c(hero, 0);
            }
        }
    }
    .icon {
        transition: 500ms transform;
    }
}
.badge-count {
    position: absolute;
    top: 0;
    right: 0;
    min-width: rem(16);
    font: 700 rem(8) / rem(16) $f;
    padding: 0 rem(6);
    background: c(hero);
    color: sc(hero);
    border-radius: rem(10);
    transform: translate(25%, -20%) scale(0);
    letter-spacing: rem(0.5);
    animation: badge-reveal 300ms forwards;
    @include over(hero, danger);
    @keyframes badge-reveal {
        50% {
            transform: translate(25%, -20%) scale(1.5);
        }
        100% {
            transform: translate(25%, -20%) scale(1);
        }
    }
}

