@import '../../assets/scss/magic';
.intro-holder {
    width: 100%;
    min-height: 100vh;
    display: flex;
    padding: rem(160) 0 rem(120);
    position: relative;
    color: c(d);
    transition: 0.6s min-height, 0.6s paddding;
    will-change: min-height;
    transform: translate3d(0,0,0);
    @include mobile {
        padding: rem(140) 0 rem(120);
    }
    &.a-secondary {
        --animate: #{rem(300)};
        min-height: 70vh;
        .s-portfolioSubPage & {
            min-height: rem(200);
            padding: 0;
        }
        @include mobile {
            min-height: 60vh;
        }
    }
    .s-404 &, .s-thankYou & {
        padding: rem(50) 0;
        overflow: hidden;
    }
}
.intro-content {
    margin: auto;
    width: 100%;
    display: grid;
    grid-template-columns: calc(50% - #{rem(50)}) calc(50% - #{rem(50)});
    grid-column-gap: rem(100);
    position: relative;
    z-index: 1;
    @include mobile {
        grid-template-columns: 100%;
        grid-row-gap: rem(30);
    }
    > li {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }
    h1 {
        --line-height: #{rem(105)};
        --font-size: #{rem(83)};
        font: 100 var(--font-size) / var(--line-height) $f;
        letter-spacing: -rem(4.2);
        display: flex;
        flex-direction: column;
        align-items: stretch;
        transition: 0.6s height;
        transform: translate3d(0,0,0);
        @include mobile {
            --line-height: #{rem(76)};
            --font-size: #{rem(64)};
        }
        span {
            display: block;
            white-space: nowrap;
            transition: 300ms font-size;
            &.highlight {
                @include accent-title;
                font-weight: 700;
            }
            &.medium {
                @include mobile {
                    font-size: rem(56);
                }
            }
        }
    }
    h2 {
        font: 300 rem(18) / 2 $f;
    }
}
.intro-button-holder {
    padding: 0 rem(6) rem(6);
    margin: 0 -rem(6) -rem(6);
    display: flex;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
    transition: 100ms max-height, 100ms opacity, 100ms max-height;
    &.show {
        transition: 200ms max-height, 200ms opacity, 200ms max-height;
        max-height: rem(100);
        padding-top: rem(30);
        opacity: 1;
    }
}
.video-holder {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: -1;
    pointer-events: none;
    transition: 1s transform 0.3s;
    display: flex;
    transform: translate3d(0, 35%, 0);
    will-change: trnasform;
    @include mobile {
        transform: translate3d(0, 35%, 0);
    }
    .a-secondary & {
        transform: translate3d(0, -45%, 0);
        @include mobile {
            transform: translate3d(0, -40%, 0);
        }
    }
    .video-animate {
        width: 100%;
        height: 100%;
    }
    video {
        filter: blur(rem(10));
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include mobile {
            object-fit: cover;
        }
    }
}
