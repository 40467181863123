@import '../../assets/scss/magic';
.animated-holder {
    width: 100%;
    opacity: 0;
    &.animate {
        animation: fadeInUp 0.6s forwards;
        .sticky .sd-up & {
            --directtion: -1;
        }
        &.a-fadeIn {
            animation-name: fadeIn;
        }
    }
}
@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translate3d(0, calc(var(--animate, #{rem(400)}) * var(--directtion, 1)), 0);
    }
    50%, to {
        opacity: 1;
    }
    to {
        transform: translate3d(0, 0, 0);
    }
}
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
